@font-face {
  font-family: Lanna;
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/TF%20Lanna.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Lanna;
  font-style: normal;
  font-weight: bold;
  src: url('/static/fonts/TF%20Lanna%20Bol.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Heavent;
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/DB%20Heavent%20v3.2.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Heavent;
  font-style: normal;
  font-weight: 500;
  src: url('/static/fonts/DB%20Heavent%20Med%20v3.2.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Heavent;
  font-style: normal;
  font-weight: bold;
  src: url('/static/fonts/DB%20Heavent%20Bd%20v3.2.woff2') format('woff2');
  font-display: swap;
}

html {
  font-size: 16px;
  height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Heavent;
  display: flex;
  flex-direction: column;
  overscroll-behavior-y: none;
  scroll-behavior: smooth;
}

@media (min-width: 1024px) {

  html,
  body {
    min-height: 100vh;
  }
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.MuiTooltip-popper {
  z-index: 2 !important;
}

.strike-center {
  display: inline-block;
  text-decoration: none;
  position: relative;
}

.strike-center:after {
  content: '';
  display: block;
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 1px solid;
}

.MuiButton-containedPrimary:hover {
  background: #00532a !important;
  opacity: 0.9 !important;
}

.MuiTooltip-popper {
  z-index: 99;
}

@media (max-width: 768px) {
  .title {
    font-size: 24px;
  }
}

@media (max-width: 320px) {
  .g-recaptcha {
    transform: scale(0.795);
    transform-origin: 0 0;
  }
}

.MuiButton-containedPrimaryCPD {
  color: white !important;
}
